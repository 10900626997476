import React from "react";
import { Button, Skeleton, message } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

import {
  CardElement,
  Elements,
  CardExpiryElement,
  ElementsConsumer,
  CardNumberElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_WALLET_US);

class PaymentForm extends React.Component {
  state = {
    stripeObj: null,
    loading: false,
    cardArr: [],
    error: {
      code: "",
      message: "",
      type: "",
    },
  };

  handleSubmit = async (event) => {
    // event.preventDefault();

    this.setState({ loading: true });

    const {
      error,
      paymentMethod,
    } = await this.props.stripe.createPaymentMethod({
      type: "card",
      card: this.props.elements.getElement(CardNumberElement),
    });

    this.setState({ error: error });

    if (error) {
      this.setState({ loading: false });
    }

    if (paymentMethod) {
      const cardElement = this.props.elements.getElement(CardNumberElement);
      let token = await this.props.stripe.createToken(cardElement);

      axios({
        url: process.env.REACT_APP_API_URL + "/stripe/set_payment_method",
        method: "post",
        withCredentials: true,
        data: { token: token.token.id },
      })
        .then((res) => {
          this.setState({ loading: false });

          if (res.data.success === 1) {
            message.success(res.data.msg);
            this.props.loadData();
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.setState({
            loading: false,
          });
        });

      console.log(token);
    }
  };

  render() {
    const inputStyle = {
      iconColor: "#666EE8",
      color: "#31325F",
      lineHeight: "40px",
      fontWeight: 400,

      fontSize: "16px",

      "::placeholder": {
        color: "#CFD7E0",
      },
    };
    return (
      <>
        <div id="fade-in" className="edit-payment">
          <div className="stripe-header">
            <h1>Update Your Payment Method</h1>
          </div>

          <div className="stripe-card-wraper">
            {!this.props.stripe ? (
              <Skeleton />
            ) : (
              <div className="payment-element-wraper">
                <h1>Enter your Card Details</h1>
                <div className="payment-element">
                  <form>
                    <CardNumberElement
                      options={{
                        showIcon: true,
                        style: {
                          base: inputStyle,
                        },
                      }}
                    />
                    <CardCvcElement
                      options={{
                        style: {
                          base: inputStyle,
                        },
                      }}
                    />
                    <CardExpiryElement
                      options={{
                        style: {
                          base: inputStyle,
                        },
                      }}
                    />

                    <div className="error-div">
                      {!!this.state.error && !!this.state.error.message && (
                        <div>{this.state.error.message}</div>
                      )}
                    </div>

                    <div className="payment-btn-div">
                      <Button
                        onClick={() => this.handleSubmit()}
                        type="primary"
                        loading={this.state.loading}
                      >
                        Update Payment Method
                      </Button>
                    </div>
                  </form>
                </div>

                <div className="secure">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.967"
                    height="22"
                    viewBox="0 0 16.967 22"
                  >
                    <path
                      id="lock_FILL1_wght200_GRAD200_opsz48"
                      d="M186.967-841a1.894,1.894,0,0,1-1.39-.577,1.894,1.894,0,0,1-.577-1.39v-10.649a1.886,1.886,0,0,1,.577-1.4,1.908,1.908,0,0,1,1.39-.568h1.646V-858.1a4.733,4.733,0,0,1,1.419-3.481A4.7,4.7,0,0,1,193.484-863a4.7,4.7,0,0,1,3.453,1.419,4.735,4.735,0,0,1,1.418,3.481v2.518H200a1.908,1.908,0,0,1,1.39.568,1.886,1.886,0,0,1,.577,1.4v10.649a1.894,1.894,0,0,1-.577,1.39A1.894,1.894,0,0,1,200-841Zm6.524-5.378a1.809,1.809,0,0,0,1.328-.55,1.8,1.8,0,0,0,.55-1.324,1.843,1.843,0,0,0-.557-1.322,1.794,1.794,0,0,0-1.335-.574,1.768,1.768,0,0,0-1.328.576,1.88,1.88,0,0,0-.55,1.338,1.769,1.769,0,0,0,.557,1.308A1.839,1.839,0,0,0,193.491-846.378Zm-3.62-9.2H197.1V-858.1a3.524,3.524,0,0,0-1.053-2.582,3.472,3.472,0,0,0-2.558-1.06,3.476,3.476,0,0,0-2.559,1.06,3.522,3.522,0,0,0-1.055,2.582Z"
                      transform="translate(-185 863)"
                      fill="#898989"
                    />
                  </svg>
                  Guaranteed safe & secure checkout
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
class StripePaymentForm extends React.Component {
  render() {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: this.props.clientSecretKey,
        }}
      >
        <ElementsConsumer>
          {(ctx) => (
            <PaymentForm
              loadData={() => this.props.loadData()}
              planName={this.props.planName}
              state={this.props.state}
              setState={(state) => this.props.setState(state)}
              plans={this.props.plans}
              planType={this.props.planType}
              clientSecretKey={this.props.clientSecretKey}
              tabHandler={(e) => this.props.tabHandler(e)}
              {...ctx}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}
export default StripePaymentForm;
